import { getOrCreateEnrollmentId } from '../../lib/utils/enrollmentUtil';
import getReferralId from '../../lib/utils/getReferralId';
import has from 'lodash/has';
import invert from 'lodash/invert';
import {
  MONTH_MAPPER,
  GENDER,
  MONTH_MAPPER_REGISTRATION,
} from '../../../src/transformers/contactInfo.transform';
import {
  COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE,
  COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER,
  COMBINED_CONSENT_MEDIUM_DIRECT_EMAIL,
  COMBINED_CONSENT_MEDIUM_EMAIL,
  COMBINED_CONSENT_MEDIUM_INSIGHT_SURVEYS,
  COMBINED_CONSENT_MEDIUM_PHONE,
  COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE,
  COMBINED_CONSENT_MEDIUM_SMS,
  consentsMap,
  PRIMARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE,
  PRIMARY_CONSENT_MEDIUM_AUTOMATED_DIALER,
  PRIMARY_CONSENT_MEDIUM_PRERECORDED_VOICE,
  SECONDARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE,
  SECONDARY_CONSENT_MEDIUM_AUTOMATED_DIALER,
  SECONDARY_CONSENT_MEDIUM_PHONE,
  SECONDARY_CONSENT_MEDIUM_PRERECORDED_VOICE,
} from './constants';
import { EMAIL_TEMPLATES } from '../../constants';
import { post } from '../../lib/utils/axiosFetcher';
import moment from 'moment';

interface IPrimaryConsents {
  isPrivacyPolicyAccepted: boolean;
  isContactMediumArtificialVoice: string | null;
  isContactMediumAutomatedDialer: string | null; // isContactMediumRecordedCall replace with isContactMediumAutomatedDialer
  isContactMediumDirectMail: string | null;
  isContactMediumEmail: string | null;
  isContactMediumPhone: string | null;
  isContactMediumPreRecordedVoice: string | null;
  isContactMediumSms: string | null;
  privacyPolicyAcceptedDateTime: string | null;
  emailOptInDate: string | null;
  emailOptOutDate: string | null;
  smsOptInDate: string | null;
  smsOptOutDate: string | null;
}

interface ISecondaryConsents {
  isSecondaryConsentAccepted: boolean;
  isSecondaryContactMediumArtificialVoice: string | null;
  isSecondaryContactMediumAutomatedDialer: string | null; // isSecondaryContactMediumRecordedCall replace with isSecondaryContactMediumAutomatedDialer
  isSecondaryContactMediumDirectMail: string | null;
  isSecondaryContactMediumEmail: string | null;
  isSecondaryContactMediumInsightSurveys: string | null;
  isSecondaryContactMediumPhone: string | null;
  isSecondaryContactMediumPreRecordedVoice: string | null;
  isSecondaryContactMediumSms: string | null;
  secondaryConsentAcceptedDateTime: string | null;
}

interface IEmailPayload {
  email: string;
  mailIdList: string;
  emailType: string;
  baseUrl: string;
  myProfileUrl: string;
  resendAttemptCount: number;
  siteSettingsId: string;
  language: string;
}

export const validateRecaptcha = async (
  gReCaptchaToken: string,
  language: string,
  siteRootPath: string
) => {
  return fetch('/api/verifyCaptcha', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      gRecaptchaToken: gReCaptchaToken,
      language,
      siteRootPath,
    }),
  });
};

const convertToYesNo = (value: boolean | undefined): string | null => {
  if (value === true) {
    return 'Yes';
  } else return 'No';
};

export const getPrimaryConsents = (formData: any): IPrimaryConsents => {
  const currentData = new Date();
  const formattedDate = currentData.toISOString();
  return {
    isPrivacyPolicyAccepted: !!formData?.PrimaryConsentMedium,
    isContactMediumArtificialVoice: has(formData, 'PrimaryConsentMediumArtificialVoice')
      ? convertToYesNo(formData?.PrimaryConsentMediumArtificialVoice)
      : null,
    isContactMediumAutomatedDialer: has(formData, 'PrimaryConsentMediumAutomatedDialer')
      ? convertToYesNo(formData?.PrimaryConsentMediumAutomatedDialer)
      : null,
    isContactMediumDirectMail: has(formData, 'PrimaryConsentMediumDirectEmail')
      ? convertToYesNo(formData?.PrimaryConsentMediumDirectEmail)
      : null,
    isContactMediumEmail: has(formData, 'PrimaryConsentMediumEmail')
      ? convertToYesNo(formData?.PrimaryConsentMediumEmail)
      : null,
    isContactMediumPhone: has(formData, 'PrimaryConsentMediumPhone')
      ? convertToYesNo(formData?.PrimaryConsentMediumPhone)
      : null,
    isContactMediumPreRecordedVoice: has(formData, 'PrimaryConsentMediumPrerecordedVoice')
      ? convertToYesNo(formData?.PrimaryConsentMediumPrerecordedVoice)
      : null,
    isContactMediumSms: has(formData, 'PrimaryConsentMediumSMS')
      ? formData.isMobile
        ? convertToYesNo(formData?.PrimaryConsentMediumSMS)
        : 'No'
      : null,

    emailOptInDate: formData?.PrimaryConsentMediumEmail ? formattedDate : null,
    smsOptInDate: formData.isMobile && formData?.PrimaryConsentMediumSMS ? formattedDate : null,
    privacyPolicyAcceptedDateTime: formData?.PrimaryConsentMedium ? formattedDate : null,
    smsOptOutDate: null,
    emailOptOutDate: null,
  };
};

export const getSecondaryConsents = (formData: any): ISecondaryConsents => {
  const currentData = new Date();
  const formattedDate = currentData.toISOString();
  return {
    isSecondaryConsentAccepted: has(formData, 'SecondaryConsentMediumArtificialVoice')
      ? formData?.SecondaryConsentMedium === undefined
        ? false
        : formData?.SecondaryConsentMedium
      : null,
    isSecondaryContactMediumArtificialVoice: has(formData, 'SecondaryConsentMediumArtificialVoice')
      ? convertToYesNo(formData?.SecondaryConsentMediumArtificialVoice)
      : null,
    isSecondaryContactMediumAutomatedDialer: has(formData, 'SecondaryConsentMediumAutomatedDialer')
      ? convertToYesNo(formData?.SecondaryConsentMediumAutomatedDialer)
      : null,
    isSecondaryContactMediumDirectMail: has(formData, 'SecondaryConsentMediumDirectEmail')
      ? convertToYesNo(formData?.SecondaryConsentMediumDirectEmail)
      : null,
    isSecondaryContactMediumEmail: has(formData, 'SecondaryConsentMediumEmail')
      ? convertToYesNo(formData?.SecondaryConsentMediumEmail)
      : null,
    isSecondaryContactMediumInsightSurveys: has(formData, 'SecondaryConsentMediumInsightSurveys')
      ? convertToYesNo(formData?.SecondaryConsentMediumInsightSurveys)
      : null,
    isSecondaryContactMediumPhone: has(formData, 'SecondaryConsentMediumPhone')
      ? convertToYesNo(formData?.SecondaryConsentMediumPhone)
      : null,
    isSecondaryContactMediumPreRecordedVoice: has(
      formData,
      'SecondaryConsentMediumPrerecordedVoice'
    )
      ? convertToYesNo(formData?.SecondaryConsentMediumPrerecordedVoice)
      : null,
    isSecondaryContactMediumSms: has(formData, 'SecondaryConsentMediumSMS')
      ? formData.isMobile
        ? convertToYesNo(formData?.SecondaryConsentMediumSMS)
        : 'No'
      : null,
    secondaryConsentAcceptedDateTime: formData?.SecondaryConsentMedium ? formattedDate : null,
  };
};

const combinedConsent = (formData: any) => {
  const consentInfo: any = {};
  const secondaryConsentInfo: any = {};
  const currentData = new Date();
  const formattedDate = currentData.toISOString();

  if (formData[COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER] === true) {
    if (!formData.hasOwnProperty(COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE))
      formData[COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE] = true;
    if (!formData.hasOwnProperty(COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE))
      formData[COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE] = true;
  }

  if (formData[COMBINED_CONSENT_MEDIUM_PHONE]) {
    if (!formData.hasOwnProperty(COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE))
      formData[COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE] = true;
    if (!formData.hasOwnProperty(COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER))
      formData[COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER] = true;
    if (!formData.hasOwnProperty(COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE))
      formData[COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE] = true;
  }

  // Assigning values to the primary and secondary consent based on the combined consunt input

  consentInfo['isContactMediumArtificialVoice'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE])
    : null;
  secondaryConsentInfo['isSecondaryContactMediumArtificialVoice'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE])
    : null;

  consentInfo['isContactMediumAutomatedDialer'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER])
    : null;
  secondaryConsentInfo['isSecondaryContactMediumAutomatedDialer'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER])
    : null;

  consentInfo['isContactMediumDirectMail'] = has(formData, COMBINED_CONSENT_MEDIUM_DIRECT_EMAIL)
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_DIRECT_EMAIL])
    : null;
  secondaryConsentInfo['isSecondaryContactMediumDirectMail'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_DIRECT_EMAIL
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_DIRECT_EMAIL])
    : null;

  consentInfo['isContactMediumEmail'] = has(formData, COMBINED_CONSENT_MEDIUM_EMAIL)
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_EMAIL])
    : null;
  secondaryConsentInfo['isSecondaryContactMediumEmail'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_EMAIL
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_EMAIL])
    : null;

  consentInfo['isContactMediumInsightSurveys'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_INSIGHT_SURVEYS
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_INSIGHT_SURVEYS])
    : null;
  secondaryConsentInfo['isSecondaryContactMediumInsightSurveys'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_INSIGHT_SURVEYS
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_INSIGHT_SURVEYS])
    : null;

  consentInfo['isContactMediumPhone'] = has(formData, COMBINED_CONSENT_MEDIUM_PHONE)
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_PHONE])
    : null;
  secondaryConsentInfo['isSecondaryContactMediumPhone'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_PHONE
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_PHONE])
    : null;

  consentInfo['isContactMediumPreRecordedVoice'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE])
    : null;
  secondaryConsentInfo['isSecondaryContactMediumPreRecordedVoice'] = has(
    formData,
    COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE
  )
    ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE])
    : null;

  consentInfo['isContactMediumSms'] = has(formData, COMBINED_CONSENT_MEDIUM_SMS)
    ? formData?.isMobile
      ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_SMS])
      : 'No'
    : null;
  secondaryConsentInfo['isSecondaryContactMediumSms'] = has(formData, COMBINED_CONSENT_MEDIUM_SMS)
    ? formData?.isMobile
      ? convertToYesNo(formData[COMBINED_CONSENT_MEDIUM_SMS])
      : 'No'
    : null;

  consentInfo['isPrivacyPolicyAccepted'] = formData?.CombinedConsentMedium
    ? formData?.CombinedConsentMedium
    : null;
  consentInfo['emailOptInDate'] = formData?.CombinedConsentMediumEmail ? formattedDate : null;
  consentInfo['smsOptInDate'] =
    formData?.isMobile && formData?.CombinedConsentMediumSMS ? formattedDate : null;
  consentInfo['privacyPolicyAcceptedDateTime'] = formData?.CombinedConsentMedium
    ? formattedDate
    : null;
  consentInfo['smsOptOutDate'] = null;
  consentInfo['emailOptOutDate'] = null;

  secondaryConsentInfo['isSecondaryConsentAccepted'] = formData?.CombinedConsentMedium
    ? formData?.CombinedConsentMedium
    : null;
  secondaryConsentInfo['secondaryConsentAcceptedDateTime'] = formData?.CombinedConsentMedium
    ? formattedDate
    : null;
  secondaryConsentInfo['combinedConsentAccepted'] = formData?.CombinedConsentMedium
    ? formData?.CombinedConsentMedium
    : null;
  secondaryConsentInfo['combinedConsentAcceptedDateTime'] = formData?.CombinedConsentMedium
    ? formattedDate
    : null;

  return {
    consentInfo,
    secondaryConsentInfo,
  };
};

export const getConsentsData = (formData: any) => {
  if (formData.hasOwnProperty('CombinedConsentMedium')) {
    const data = combinedConsent(formData);
    return data;
  } else {
    if (formData[PRIMARY_CONSENT_MEDIUM_AUTOMATED_DIALER] === true) {
      if (!formData.hasOwnProperty(PRIMARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE))
        formData[PRIMARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE] = true;
      if (!formData.hasOwnProperty(PRIMARY_CONSENT_MEDIUM_PRERECORDED_VOICE))
        formData[PRIMARY_CONSENT_MEDIUM_PRERECORDED_VOICE] = true;
    }

    if (formData[SECONDARY_CONSENT_MEDIUM_AUTOMATED_DIALER]) {
      if (!formData.hasOwnProperty(SECONDARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE))
        formData[SECONDARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE] = true;
      if (!formData.hasOwnProperty(SECONDARY_CONSENT_MEDIUM_PRERECORDED_VOICE))
        formData[SECONDARY_CONSENT_MEDIUM_PRERECORDED_VOICE] = true;
    }

    if (formData[SECONDARY_CONSENT_MEDIUM_PHONE]) {
      if (!formData.hasOwnProperty(SECONDARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE))
        formData[SECONDARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE] = true;
      if (!formData.hasOwnProperty(SECONDARY_CONSENT_MEDIUM_AUTOMATED_DIALER))
        formData[SECONDARY_CONSENT_MEDIUM_AUTOMATED_DIALER] = true;
      if (!formData.hasOwnProperty(SECONDARY_CONSENT_MEDIUM_PRERECORDED_VOICE))
        formData[SECONDARY_CONSENT_MEDIUM_PRERECORDED_VOICE] = true;
    }

    return {
      consentInfo: getPrimaryConsents(formData),
      secondaryConsentInfo: getSecondaryConsents(formData),
    };
  }
};

export const constructConditionsOfInterest = (conditionsList: any, formatedCOIList: any) => {
  if (conditionsList) {
    const conditions: any = [];
    conditionsList.map((condition: any) => {
      if (
        condition?.sitecoreID !== '' ||
        condition?.sitecoreID !== undefined ||
        condition?.sitecoreId !== null ||
        condition?.conditionId !== '' ||
        condition?.conditionId !== undefined ||
        condition?.conditionId !== null
      ) {
        const coiItem =
          formatedCOIList &&
          formatedCOIList?.filter(
            (formatedCOI: any) => condition?.sitecoreId === formatedCOI?.sitecoreId
          )[0];

        if (coiItem && (coiItem?.category !== '' || coiItem?.category !== undefined)) {
          conditions.push(coiItem);
        }
      }
      // const temp =
      //   formatedCOIList &&
      //   formatedCOIList?.filter(
      //     (formatedCOI: any) => condition?.sitecoreId === formatedCOI?.sitecoreId
      //   )[0];
      // conditions.push(temp);
    });
    return conditions;
  } else {
    return [];
  }
};

const disableEmailField = (key: string) => {
  const emailInputField = document.getElementById(key);
  if (emailInputField && (emailInputField as HTMLInputElement)?.value !== '') {
    emailInputField.setAttribute('readonly', 'true');
    const outerDiv = document.querySelector(`div[data-testid="${key}"]`);
    if (outerDiv) {
      outerDiv.setAttribute('style', 'background: #f2f2f2');
    }
  }
};

export const prepopulateMyProfileForMinorForm = (myProfileData: any) => {
  if (myProfileData?.basicDetails?.email) {
    setTimeout(() => disableEmailField('ParentEmail'), 800);
  }
  return {
    ParentEmail: myProfileData?.basicDetails?.email || '',
  };
};
export const prepopulateMyProfileForCaregiverForm = (myProfileData: any) => {
  if (myProfileData?.basicDetails?.email) {
    setTimeout(() => disableEmailField('CaregiverEmail'), 800);
  }
  return {
    CaregiverEmail: myProfileData?.basicDetails?.email || '',
  };
};

export const prepopulateMyProfileForAdultForm = (myProfileData: any, existingValues: any) => {
  const dobDate = moment.parseZone(myProfileData?.basicDetails?.dob).format();
  const dobYear = moment.parseZone(dobDate).year();
  const dobDay = moment.parseZone(dobDate).date();

  const dobMonth =
    MONTH_MAPPER_REGISTRATION[
      (moment.parseZone(dobDate).month() + 1) as keyof typeof MONTH_MAPPER_REGISTRATION
    ];

  if (myProfileData?.basicDetails?.email) {
    setTimeout(() => disableEmailField('Email'), 800);
  }
  const data = {
    FirstName: existingValues['FirstName']
      ? existingValues['FirstName']
      : myProfileData?.basicDetails?.firstName,
    LastName: existingValues['LastName']
      ? existingValues['LastName']
      : myProfileData?.basicDetails?.lastName,
    ContactNumber: existingValues['ContactNumber']
      ? existingValues['ContactNumber']
      : myProfileData?.basicDetails?.mobile,
    Address: existingValues['Address']
      ? existingValues['Address']
      : myProfileData?.basicDetails?.address,
    City: existingValues['City'] ? existingValues['City'] : myProfileData?.basicDetails?.city,
    State: existingValues['State'] ? existingValues['State'] : myProfileData?.basicDetails?.state,
    Zipcode: existingValues['Zipcode']
      ? existingValues['Zipcode']
      : myProfileData?.basicDetails?.zipCode,
    Day: existingValues['Day'] ? existingValues['Day'] : dobDay,
    Month: existingValues['Month'] ? existingValues['Month'] : dobMonth,
    Year: existingValues['Year'] ? existingValues['Year'] : dobYear,
    Gender: existingValues['Gender']
      ? existingValues['Gender']
      : myProfileData?.basicDetails?.gender,
    Email: existingValues['Email'] ? existingValues['Email'] : myProfileData?.basicDetails?.email,
    isMobile: existingValues['isMobile']
      ? existingValues['isMobile']
      : myProfileData?.basicDetails?.isMobile,
  };
  return data;
};

export const prePopulateSceeenerDataForAdultForm = (
  screenerData: any,
  prePopulateSceeenerData: any,
  existingValues: any,
  geolocation: any,
  sitecoreFieldsForContactInfo: any,
  cdpData: any
) => {
  const { d, m, y, g, zipCode } = screenerData;
  const invertedMonthMapper = invert(MONTH_MAPPER);
  const monthKey = Number(m)?.toString();
  const month = invertedMonthMapper[monthKey];
  const isDOBPrepopulateEnabled = prePopulateSceeenerData?.['DOB']?.prePopulate;
  const isGenderPrepopulate = prePopulateSceeenerData?.['Gender']?.prePopulate;
  const isZipcodePrepopulate = prePopulateSceeenerData?.['Zipcode']?.prePopulate;
  const data = {
    Day: isDOBPrepopulateEnabled ? Number(d)?.toString() : existingValues['Day'],
    Month: isDOBPrepopulateEnabled ? month : existingValues['Month'],
    Year: isDOBPrepopulateEnabled ? y : existingValues['Year'],
    Gender: isGenderPrepopulate ? GENDER[g as keyof typeof GENDER] : existingValues['Gender'],
    City: existingValues['City']
      ? existingValues['City']
      : geolocation.location?.city && sitecoreFieldsForContactInfo['adult']['City']
      ? geolocation.location.city
      : undefined,
    State: existingValues['State']
      ? existingValues['State']
      : cdpData?.patientState && sitecoreFieldsForContactInfo['adult']['State']
      ? cdpData?.patientState
      : geolocation.location?.state && sitecoreFieldsForContactInfo['adult']['State']
      ? geolocation.location.state
      : undefined,
    Zipcode: existingValues['Zipcode']
      ? existingValues['Zipcode']
      : isZipcodePrepopulate && zipCode
      ? zipCode?.toString()
      : cdpData?.patientZipCode && sitecoreFieldsForContactInfo['adult']['Zipcode']
      ? cdpData?.patientZipCode
      : geolocation.location?.zipcode && sitecoreFieldsForContactInfo['adult']['Zipcode']
      ? geolocation.location.zipcode
      : undefined,
  };
  return data;
};

export const prePopulateSceeenerDataForMinorForm = (
  screenerData: any,
  prePopulateSceeenerData: any,
  existingValues: any,
  geolocation: any,
  sitecoreFieldsForContactInfo: any,
  cdpData: any
) => {
  const { d, m, y, g, zipCode } = screenerData;
  const invertedMonthMapper = invert(MONTH_MAPPER);
  const monthKey = Number(m)?.toString();
  const month = invertedMonthMapper[monthKey];
  const isDOBPrepopulateEnabled = prePopulateSceeenerData?.['DOB']?.prePopulate;
  const isGenderPrepopulate = prePopulateSceeenerData?.['Gender']?.prePopulate;
  const isParticipantZipcodePrepopulate =
    prePopulateSceeenerData?.['ParticipantZipcode']?.prePopulate;
  const isZipCodePrepopulate = prePopulateSceeenerData?.['ZipCode']?.prePopulate;

  const data = {
    Day: isDOBPrepopulateEnabled ? Number(d)?.toString() : existingValues['Day'],
    Month: isDOBPrepopulateEnabled ? month : existingValues['Month'],
    Year: isDOBPrepopulateEnabled ? y : existingValues['Year'],
    Gender: isGenderPrepopulate ? GENDER[g as keyof typeof GENDER] : existingValues['Gender'],

    ParentState: existingValues['ParentState']
      ? existingValues['ParentState']
      : cdpData?.patientState && sitecoreFieldsForContactInfo['minor']['ParentState']
      ? cdpData?.patientState
      : geolocation.location?.state && sitecoreFieldsForContactInfo['minor']['ParentState']
      ? geolocation.location.state
      : undefined,

    ParentCity: existingValues['ParentCity']
      ? existingValues['ParentCity']
      : geolocation.location?.city && sitecoreFieldsForContactInfo['minor']['ParentCity']
      ? geolocation.location.city
      : undefined,

    ZipCode: existingValues['ZipCode']
      ? existingValues['ZipCode']
      : isZipCodePrepopulate && zipCode
      ? zipCode?.toString()
      : cdpData?.patientZipCode && sitecoreFieldsForContactInfo['minor']['ZipCode']
      ? cdpData?.patientZipCode
      : geolocation.location?.zipcode && sitecoreFieldsForContactInfo['minor']['ZipCode']
      ? geolocation.location.zipcode
      : undefined,

    ParticipantState: existingValues['ParticipantState']
      ? existingValues['ParticipantState']
      : cdpData?.patientState && sitecoreFieldsForContactInfo['minor']['ParticipantState']
      ? cdpData?.patientState
      : geolocation.location?.state && sitecoreFieldsForContactInfo['minor']['ParticipantState']
      ? geolocation.location.state
      : undefined,

    ParticipantZipcode: existingValues['ParticipantZipcode']
      ? existingValues['ParticipantZipcode']
      : isParticipantZipcodePrepopulate && zipCode
      ? zipCode?.toString()
      : undefined,
  };
  return data;
};

export const prePopulateSceeenerDataCaregiverForm = (
  screenerData: any,
  prePopulateSceeenerData: any,
  existingValues: any,
  geolocation: any,
  sitecoreFieldsForContactInfo: any,
  cdpData: any
) => {
  const { d, m, y, g, zipCode } = screenerData;
  const invertedMonthMapper = invert(MONTH_MAPPER);
  const monthKey = Number(m)?.toString();
  const month = invertedMonthMapper[monthKey];
  const isDOBPrepopulateEnabled = prePopulateSceeenerData?.['DOB']?.prePopulate;
  const isGenderPrepopulate = prePopulateSceeenerData?.['Gender']?.prePopulate;
  const isCaregiverZipCodePrepopulate = prePopulateSceeenerData?.['CaregiverZipCode']?.prePopulate;
  const isParticipantZipcodePrepopulate =
    prePopulateSceeenerData?.['ParticipantZipcode']?.prePopulate;
  const isPatientZipcodeFieldAvailable =
    sitecoreFieldsForContactInfo['caregiver']['ParticipantZipcode'] || false;
  const data = {
    Day: isDOBPrepopulateEnabled ? Number(d)?.toString() : existingValues['Day'],
    Month: isDOBPrepopulateEnabled ? month : existingValues['Month'],
    Year: isDOBPrepopulateEnabled ? y : existingValues['Year'],
    Gender: isGenderPrepopulate ? GENDER[g as keyof typeof GENDER] : existingValues['Gender'],
    CaregiverZipCode: existingValues['CaregiverZipCode']
      ? existingValues['CaregiverZipCode']
      : isCaregiverZipCodePrepopulate && zipCode
      ? zipCode?.toString()
      : cdpData?.patientZipCode &&
        !isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['CaregiverZipCode']
      ? cdpData?.patientZipCode
      : geolocation.location?.zipcode &&
        !isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['CaregiverZipCode']
      ? geolocation.location.zipcode
      : undefined,
    CaregiverCity: existingValues['CaregiverCity']
      ? existingValues['CaregiverCity']
      : geolocation?.location?.city &&
        !isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['CaregiverCity']
      ? geolocation.location.city
      : undefined,
    CaregiverState: existingValues['CaregiverState']
      ? existingValues['CaregiverState']
      : cdpData?.patientState &&
        !isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['CaregiverState']
      ? cdpData?.patientState
      : geolocation?.location?.state &&
        !isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['CaregiverState']
      ? geolocation.location.state
      : undefined,

    ParticipantZipcode: existingValues['ParticipantZipcode']
      ? existingValues['ParticipantZipcode']
      : isParticipantZipcodePrepopulate && zipCode
      ? zipCode?.toString()
      : cdpData?.patientZipCode &&
        isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['ParticipantZipcode']
      ? cdpData?.patientZipCode
      : geolocation?.location?.zipcode &&
        isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['ParticipantZipcode']
      ? geolocation.location.zipcode
      : undefined,
    ParticipantCity: existingValues['ParticipantCity']
      ? existingValues['ParticipantCity']
      : geolocation?.location?.city &&
        isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['ParticipantCity']
      ? geolocation.location.city
      : undefined,
    ParticipantState: existingValues['ParticipantState']
      ? existingValues['ParticipantState']
      : cdpData?.patientState &&
        isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['ParticipantState']
      ? cdpData?.patientState
      : geolocation?.location?.state &&
        isPatientZipcodeFieldAvailable &&
        sitecoreFieldsForContactInfo['caregiver']['ParticipantState']
      ? geolocation.location.state
      : undefined,
  };
  return data;
};

enum MY_PROFILE_GENDER {
  'Male' = 'Male',
  'Female' = 'Female',
  'Other' = 'Other',
  'Prefer not to answer' = 'Prefernottoanswer',
  'Unknown' = 'Prefernottoanswer',
  'male' = 'Male',
  'female' = 'Female',
  'other' = 'Other',
  'unknown' = 'Prefernottoanswer',
}

export const updateGender = (matchedElm: string): void => {
  const elemToClick: HTMLElement = document.querySelector(
    `input[name=${MY_PROFILE_GENDER[matchedElm as keyof typeof MY_PROFILE_GENDER]}]`
  ) as HTMLElement;

  if (elemToClick) {
    elemToClick.click();
  }
};

export const updateConsents = (
  consentType: string,
  consentObj: {
    [key: string]: any;
  }
): void => {
  // let k: keyof typeof consentObj;
  Object.keys(consentObj).forEach((k) => {
    const matchedElm: string = consentsMap[consentType][k];
    if (matchedElm) {
      let matchedElmValue = consentObj[k];
      if (matchedElmValue === 'True' || matchedElmValue === 'Yes') {
        matchedElmValue = 'true';
      } else {
        matchedElmValue = 'false';
      }
      const elemToClick: HTMLElement = document.querySelector(
        `input[name="${matchedElm}"]`
      ) as HTMLElement;
      if (elemToClick) {
        let elemToClickExistingValue = elemToClick.getAttribute('value');
        if (elemToClickExistingValue === null) {
          elemToClickExistingValue = 'false';
        }
        if (matchedElmValue === elemToClickExistingValue) return;
        elemToClick.click();
      }
    }
  });
};

export const constructVirtualSiteData = (
  data: any,
  tenantKey: any,
  enrollmentId: any,
  potentialId: any,
  potentialParticipant_ID: any,
  successCallbackData: any
) => {
  const sitesData = data?.sitesData.length > 0 ? data?.sitesData[0] : {};
  const url = typeof window !== 'undefined' ? window.location.href : null;
  const baseUrl = url ? location.protocol + '//' + location.host : null;
  const constructedData = {
    //id: 0, //not needed
    // protocolInfoId: 0, //not needed
    tenantKey,
    enrollmentId,
    siteInfo: {
      siteCode: sitesData.siteCode,
      siteAddress: sitesData.siteAddress,
      siteCity: sitesData.siteCity,
      siteCountry: sitesData.siteCountry,
      siteName: sitesData.siteName,
      contactPhoneNumber: sitesData.contactPhoneNumber,
      siteZipCode: sitesData.siteZipCode,
      siteState: sitesData.siteState,
      warmTransferNumber: sitesData.warmTransferNumber,
      econsentUrl: sitesData?.eConsentUrl,
      piname: sitesData.piName,
      isSiteConfirmed: true,
      protocolInfo: {
        potentialId,
        patientId: successCallbackData.patientId, // ui check
        potentialParticipantId: potentialParticipant_ID, //ui check
        protocol: sitesData.protocol,
        //TODO: these 2 fields will be used in future as per disscussion with backend team
        //keeping it empty string until UI have these
        sponsorId: '', // need details on this
        studyId: '', //need details on this
        studyName: sitesData.studyName,
        projectCode: sitesData.projectCode, //
        nctid: sitesData.nctid,
        sponsor: sitesData.sponsor,
        indication: sitesData.indication,
        tenantKey,
        enrollmentId,
      },
    },
    baseUrl,
    isKiElementEnabled: sitesData.isSiteKiElementsEnabled, // required for KIE
    isKiElementsSponsorConsentEnabled: false, // required for KIE
    isUserOptedKiElementConsent: '', // required for KIE
    isLifeLinkEnabled: sitesData.isLifeLinkEnabled,
    isUserOptedLifeLinkConsent: '',
    isSponsorLifeLinkEnabled: false, // need to send this value once LL integration id done
    appointmentStatus: '',
    referralId: getReferralId(getOrCreateEnrollmentId(), sitesData.siteCode),
    isHumanAPIEnabled: sitesData.isHumanAPIEnabled || false,
  };

  return constructedData;
};

export const validateSMSandIsMobile = async (
  value: any,
  trigger: any,
  setError: any,
  ContactNumberField: any
): Promise<void> => {
  const isPrimarySMSConsentVisible: any = document.querySelector(
    `input[name="PrimaryConsentMediumSMS"]`
  );
  const isSecondarySMSConsentVisible: any = document.querySelector(
    `input[name="SecondaryConsentMediumSMS"]`
  );
  const isCombinedSMSConsentVisible: any = document.querySelector(
    `input[name="CombinedConsentMediumSMS"]`
  );

  if (
    !!value?.isMobile === false &&
    ((value?.PrimaryConsentMediumSMS && isPrimarySMSConsentVisible?.type === 'checkbox') ||
      (value?.SecondaryConsentMediumSMS && isSecondarySMSConsentVisible?.type === 'checkbox') ||
      (value?.CombinedConsentMediumSMS && isCombinedSMSConsentVisible?.type === 'checkbox'))
  ) {
    setError('isMobile', {
      message: ContactNumberField?.fields?.mobileNumberValidationMessageOnSMSConsent?.value,
    });
  } else if (
    !!value?.isMobile === false &&
    !(
      value?.PrimaryConsentMediumSMS ||
      value?.SecondaryConsentMediumSMS ||
      value?.CombinedConsentMediumSMS
    )
  ) {
    await trigger('isMobile');
  } else if (value?.isMobile) {
    await trigger('isMobile');
  }
};

export const callDapApiSendEmail = async (payload: IEmailPayload): Promise<boolean> => {
  try {
    await post('/Send/AddContactsToCRMailingList', payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const callDapApiSendEmailAllDetailsAdult = async (
  contactInfoDetails: any,
  baseUrlSource: string,
  myProfileUrl: string,
  mailListId: string,
  siteSettingsId: string,
  language: string
) => {
  try {
    const mailingListId = mailListId || '';
    const emailType = EMAIL_TEMPLATES.REGISTRATION_WELCOME;
    const baseUrl = baseUrlSource || '';
    const userConditions = contactInfoDetails?.conditionOfInterest?.map(
      (condition) => condition?.text
    );
    const therapeuticArea = userConditions ? userConditions?.join(', ') : '';
    const payload = {
      mailingListId,
      source: 'CR',
      baseUrl,
      myProfileUrl: myProfileUrl,
      communityPageLink: '',
      resendAttemptCount: 0,
      subscriber: {
        emailAddress: contactInfoDetails?.patient?.emailAddress,
        name: `${contactInfoDetails?.patient?.firstName || ''} ${
          contactInfoDetails?.patient?.lastName || ''
        }`,
        firstName: contactInfoDetails?.patient?.firstName || '',
        lastName: contactInfoDetails?.patient?.lastName || '',
        phone:
          contactInfoDetails?.patient?.mobileNumber ||
          contactInfoDetails?.patient?.contactPhoneNumber ||
          '',
        dateOfBirth: contactInfoDetails?.patient?.dateofbirth || '',
        gender: contactInfoDetails?.patient?.gender || '',
        isEmailOptedIn: contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumEmail
          ? contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumEmail?.toLowerCase() ===
            'yes'
          : false,
        isSmsOptedIn: contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumSms
          ? contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumSms?.toLowerCase() ===
            'yes'
          : false,
        isSecondaryConsentAccepted:
          contactInfoDetails?.secondaryConsentInfo?.isSecondaryConsentAccepted,
        preferredLanguage: language,
        therapeuticArea,
        myProfileLink: '',
        customUnsubscribeLink: '',
        crWelcomeEmailType: emailType,
        registrationDateTime: new Date().toISOString(),
        zipCode: contactInfoDetails?.patient?.zipcode || '',
      },
      enrollmentId: contactInfoDetails?.patient?.enrollmentId || '',
      siteSettingsId: siteSettingsId,
      language: language,
      isCrEmailCheck: true,
    };
    await post('/Send/AddContactsToCRMailingListWithAllDetail', payload);
  } catch (_error) {}
};

export const callDapApiSendEmailAllDetailsDelegate = async (
  contactInfoDetails: any,
  baseUrlSource: string,
  myProfileUrl: string,
  mailListId: string,
  siteSettingsId: string,
  language: string
) => {
  try {
    const mailingListId = mailListId || '';
    const emailType = EMAIL_TEMPLATES.REGISTRATION_WELCOME;
    const baseUrl = baseUrlSource || '';
    const userConditions = contactInfoDetails?.conditionOfInterest?.map(
      (condition) => condition?.text
    );
    const therapeuticArea = userConditions ? userConditions?.join(', ') : '';
    const payload = {
      mailingListId,
      source: 'CR',
      baseUrl,
      myProfileUrl: myProfileUrl,
      communityPageLink: '',
      resendAttemptCount: 0,
      subscriber: {
        emailAddress: contactInfoDetails?.delegate?.emailAddress,
        name: `${contactInfoDetails?.delegate?.firstName || ''} ${
          contactInfoDetails?.delegate?.lastName || ''
        }`,
        firstName: contactInfoDetails?.delegate?.firstName || '',
        lastName: contactInfoDetails?.delegate?.lastName || '',
        phone:
          contactInfoDetails?.delegate?.mobileNumber ||
          contactInfoDetails?.delegate?.contactPhoneNumber ||
          '',
        dateOfBirth: contactInfoDetails?.patient?.dateofbirth || '',
        gender: contactInfoDetails?.patient?.gender || '',
        isEmailOptedIn: contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumEmail
          ? contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumEmail?.toLowerCase() ===
            'yes'
          : false,
        isSmsOptedIn: contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumSms
          ? contactInfoDetails?.secondaryConsentInfo?.isSecondaryContactMediumSms?.toLowerCase() ===
            'yes'
          : false,
        isSecondaryConsentAccepted:
          contactInfoDetails?.secondaryConsentInfo?.isSecondaryConsentAccepted,
        preferredLanguage: language,
        therapeuticArea,
        myProfileLink: '',
        customUnsubscribeLink: '',
        crWelcomeEmailType: emailType,
        registrationDateTime: new Date().toISOString(),
        zipCode:
          contactInfoDetails?.delegate?.zipcode || contactInfoDetails?.patient?.zipcode || '',
      },
      enrollmentId: contactInfoDetails?.patient?.enrollmentId || '',
      siteSettingsId: siteSettingsId,
      language: language,
      isCrEmailCheck: true,
    };
    await post('/Send/AddContactsToCRMailingListWithAllDetail', payload);
  } catch (_error) {}
};

// const removeTrailingSlash = (url: string) => {
//   if (url.endsWith('/')) {
//     return url.slice(0, -1);
//   }
//   return url;
// };
