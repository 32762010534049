//these types are commig from sitecore to map to correct item in application
//as forms are dynamic in nature
import LanguageIcon from '@mui/icons-material/Language';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { SvgIconComponent } from '@mui/icons-material';
export const contactFormFieldType = {
  CONTACT_TYPE: 'ContactStep',
  MODAL_LINK_TYPE: 'LinkText',
  CTA_BUTTON_TYPE: 'CTAButton',
  EXIT_FORM_MODAL: 'Modal',
  RECAPTCHA_CONFIGURATION: 'Configuration',
};

export const contactFormFieldKeys = {
  STATE_KEYS: ['State', 'ParticipantState', 'ParentState', 'CaregiverState'],
  USER_TIME_ZONE: 'UserTimeZone',
  PREFERRED_CONTACT_TIME: 'PreferredContactTime',
  CONDITION_OF_INTEREST: 'ConditionOfInterest',
};

export const formFieldType = {
  DROPDOWN: 'Dropdown',
  TEXTBOX: 'TextBox',
  CHECKBOX: 'CheckBox',
  DOB: 'DOB',
  RADIOBUTTON: 'RadioButton',
  SECTION_HEADING: 'SectionHeading',
};

export const PHONE_NUMBER = ['ContactNumber', 'ParentContactNumber', 'CaregiverContactNumber'];

export const clientSideDefaultRegex = {
  Address: `^[a-zA-Z0-9#'(),-.;:/ ]*$`,
  City: `^[a-zA-Z&'()-. ]*$`,
  Email: `^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$`,
  FirstName: `^[a-zA-Z',-. ]*$`,
  LastName: `^[a-zA-Z',-. ]*$`,
  ContactNumber: `^[0-9()+-. ]*$`,
  ZIPCode: `^[a-zA-Z0-9- ]*$`,
};

export const clientSideDefaultMaxLength = {
  Address: 100,
  City: 50,
  Email: 200,
  FirstName: 50,
  LastName: 50,
  ContactNumber: 17,
  ZIPCode: 17,
};

export const SITECORE_ADULT_FORM_KEYS = {
  ADDRESS: 'Address',
  CITY: 'City',
  ZIPCODE: 'Zipcode',
  STATE: 'State',
};

export const SITECORE_CR_REGISTRATION_FORM_KEYS = {
  ADDRESS: 'CrAddress',
  CITY: 'City',
  ZIPCODE: 'Zipcode',
  STATE: 'State',
};

export const SITECORE_MINOR_FORM_KEYS = {
  PARTICIPANT_ADDRESS: 'ParticipantAddress',
  PARTICIPANT_CITY: 'ParticipantCity',
  PARTICIPANT_ZIPCODE: 'ParticipantZipcode',
  PARTICIPANT_STATE: 'ParticipantState',
  PARENT_ADDRESS: 'ParentAddress',
  PARENT_CITY: 'ParentCity',
  PARENT_ZIPCODE: 'ZipCode',
  PARENT_STATE: 'ParentState',
};

export const TIME_PERIOD = {
  ONE_HUNDRED_EIGHTY_DAYS: 180,
};
export const SITECORE_CAREGIVER_FORM_KEYS = {
  PARTICIPANT_ADDRESS: 'ParticipantAddress',
  CAREGIVER_ADDRESS: 'CaregiverAddress',
  CAREGIVER_CITY: 'CaregiverCity',
  CAREGIVER_STATE: 'CaregiverState',
  CAREGIVER_ZIPCODE: 'CaregiverZipCode',
  PARTICIPANT_CITY: 'ParticipantCity',
  PARTICIPANT_ZIPCODE: 'ParticipantZipcode',
  PARTICIPANT_STATE: 'ParticipantState',
};

export const SITECORE_ZIPCODE_KEYS = [
  'Zipcode',
  'ZipCode',
  'ParticipantZipcode',
  'CaregiverZipCode',
  'ParticipantZipCode',
  'ParentZipcode',
];

export const USER_TYPE = {
  PATIENT: 'patient',
  DELEGATE: 'delegate',
};

export const PATIENT_KEY_ADDRESS = [
  SITECORE_ADULT_FORM_KEYS.ADDRESS,
  SITECORE_MINOR_FORM_KEYS.PARTICIPANT_ADDRESS,
  SITECORE_CAREGIVER_FORM_KEYS.PARTICIPANT_ADDRESS,
];

export const DELEGATEKEYADDRESS = [
  SITECORE_MINOR_FORM_KEYS.PARENT_ADDRESS,
  SITECORE_CAREGIVER_FORM_KEYS.CAREGIVER_ADDRESS,
];

export const ZIPCODE_STATE_MAPPING: { [x: string]: string } = {
  Zipcode: 'State',
  ZipCode: 'ParentState',
  CaregiverZipCode: 'CaregiverState',
  ParticipantZipcode: 'ParticipantState',
};

export const STATE_ZIPCODE_MAPPING: { [x: string]: string } = {
  State: 'Zipcode',
  ParentState: 'ZipCode',
  CaregiverState: ' CaregiverZipCode',
  ParticipantState: 'ParticipantZipcode',
};
export const SITECORE_STATE_KEYS = ['State', 'ParticipantState', 'ParentState', 'CaregiverState'];
export const SITECORE_ADDRESS_KEYS = [
  'Address',
  'CaregiverAddress',
  'ParentAddress',
  'ParticipantAddress',
  'CrAddress',
];

export const SITECORE_ADDRESS_MAPPING: { [x: string]: string } = {
  Address: 'Address',
  CaregiverAddress: 'CaregiverAddress',
  ParentAddress: 'ParentAddress',
  ParticipantAddress: 'ParticipantAddress',
};

export const CONTACT_PAGE_ADULT_FORM = 'adult';
export const CONTACT_PAGE_MINOR_FORM = 'minor';
export const CONTACT_PAGE_CAREGIVER_FORM = 'caregiver';

export const SCREEN_SIZES = {
  MOBILE_WIDTH: 576, // For mobile devices
  TABLET_WIDTH: 800, // For tablet devices
};

interface IAlertStatus {
  [key: string]: {
    [key: string]: SvgIconComponent;
  };
}
export const ALERT_STATUSES: IAlertStatus = {
  information: { icon: LanguageIcon },
  success: { icon: LanguageIcon },
  error: { icon: RemoveCircleOutlineIcon },
};

type PlacesType = 'drawing' | 'geometry' | 'localContext' | 'places' | 'visualization';

export const PLACES_API_LIBRARIES: PlacesType[] = ['places'];

export const MODAL_VARIANT = {
  EXIT_DISCLAIMER: 'exit_disclaimer',
};

export const consentsMap: {
  [key: string]: any;
} = {
  primary: {
    isPrivacyPolicyAccepted: 'PrimaryConsentMedium',
    isContactMediumPhone: 'PrimaryConsentMediumPhone',
    isContactMediumEmail: 'PrimaryConsentMediumEmail',
    isContactMediumSMS: 'PrimaryConsentMediumSMS',
    isContactMediumAutomatedDialer: 'PrimaryConsentMediumAutomatedDialer', // recheck
    isContactMediumDirectMail: 'PrimaryConsentMediumDirectEmail',
    isContactMediumArtificialVoice: 'PrimaryConsentMediumArtificialVoice',
    isContactMediumPreRecordedVoice: 'PrimaryConsentMediumPrerecordedVoice',
  },
  secondary: {
    isSecondaryConsentAccepted: 'SecondaryConsentMedium',
    isSecondaryContactMediumPhone: 'SecondaryConsentMediumPhone',
    isSecondaryContactMediumEmail: 'SecondaryConsentMediumEmail',
    isSecondaryContactMediumSMS: 'SecondaryConsentMediumSMS',
    isSecondaryContactMediumAutomatedDialer: 'SecondaryConsentMediumAutomatedDialer', // recheck
    isSecondaryContactMediumDirectMail: 'SecondaryConsentMediumDirectEmail',
    isSecondaryContactMediumArtificialVoice: 'SecondaryConsentMediumArtificialVoice',
    isSecondaryContactMediumPreRecordedVoice: 'SecondaryConsentMediumPrerecordedVoice',
    isSecondaryContactMediumInsightSurveys: 'SecondaryConsentMediumInsightSurveys',
    combinedConsentAccepted: 'CombinedConsentMedium',
  },
  combined: {
    isSecondaryConsentAccepted: 'CombinedConsentMedium',
    isSecondaryContactMediumPhone: 'CombinedConsentMediumPhone',
    isSecondaryContactMediumEmail: 'CombinedConsentMediumEmail',
    isSecondaryContactMediumSMS: 'CombinedConsentMediumSMS',
    isSecondaryContactMediumAutomatedDialer: 'CombinedConsentMediumAutomatedDialer', // recheck
    isSecondaryContactMediumDirectMail: 'CombinedConsentMediumDirectEmail',
    isSecondaryContactMediumArtificialVoice: 'CombinedConsentMediumArtificialVoice',
    isSecondaryContactMediumPreRecordedVoice: 'CombinedConsentMediumPrerecordedVoice',
    isSecondaryContactMediumInsightSurveys: 'CombinedConsentMediumInsightSurveys',
    combinedConsentAccepted: 'CombinedConsentMedium',
  },
};

export const CONTACT_FIELD_LIST = 'contactFieldList';
export const PRIMARY_CONSENT_MEDIUM = 'PrimaryConsentMedium';
export const SECONDARY_CONSENT_MEDIUM = 'SecondaryConsentMedium';
export const AUTOMATED_DIALER = 'AutomatedDialer';
export const ARTIFICIAL_VOICE = 'ArtificialVoice';
export const PRERECORDRD_VOICE = 'PrerecordedVoice';
export const PRIMARY_CONSENT_MEDIUM_AUTOMATED_DIALER = 'PrimaryConsentMediumAutomatedDialer';
export const PRIMARY_CONSENT_MEDIUM_PRERECORDED_VOICE = 'PrimaryConsentMediumPrerecordedVoice';
export const PRIMARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE = 'PrimaryConsentMediumArtificialVoice';
export const PRIMARY_CONSENT_MEDIUM_DIRECT_EMAIL = 'PrimaryConsentMediumDirectEmail';
export const PRIMARY_CONSENT_MEDIUM_EMAIL = 'PrimaryConsentMediumEmail';
export const PRIMARY_CONSENT_MEDIUM_INSIGHT_SURVEYS = 'PrimaryConsentMediumInsightSurveys';
export const PRIMARY_CONSENT_MEDIUM_SMS = 'PrimaryConsentMediumSMS';
export const PRIMARY_CONSENT_MEDIUM_PHONE = 'PrimaryConsentMediumPhone';
export const IS_MOBILE = 'isMobile';

export const SECONDARY_CONSENT_MEDIUM_ARTIFICIAL_VOICE = 'SecondaryConsentMediumArtificialVoice';
export const SECONDARY_CONSENT_MEDIUM_AUTOMATED_DIALER = 'SecondaryConsentMediumAutomatedDialer';
export const SECONDARY_CONSENT_MEDIUM_PRERECORDED_VOICE = 'SecondaryConsentMediumPrerecordedVoice';
export const SECONDARY_CONSENT_MEDIUM_PHONE = 'SecondaryConsentMediumPhone';
export const SECONDARY_CONSENT_MEDIUM_DIRECT_EMAIL = 'SecondaryConsentMediumDirectEmail';
export const SECONDARY_CONSENT_MEDIUM_EMAIL = 'SecondaryConsentMediumEmail';
export const SECONDARY_CONSENT_MEDIUM_INSIGHT_SURVEYS = 'SecondaryConsentMediumInsightSurveys';
export const SECONDARY_CONSENT_MEDIUM_SMS = 'SecondaryConsentMediumSMS';

export const COMBINED_CONSENT_MEDIUM_ARTIFICIAL_VOICE = 'CombinedConsentMediumArtificialVoice';
export const COMBINED_CONSENT_MEDIUM_AUTOMATED_DIALER = 'CombinedConsentMediumAutomatedDialer';
export const COMBINED_CONSENT_MEDIUM_PRERECORDED_VOICE = 'CombinedConsentMediumPrerecordedVoice';
export const COMBINED_CONSENT_MEDIUM_PHONE = 'CombinedConsentMediumPhone';
export const COMBINED_CONSENT_MEDIUM_DIRECT_EMAIL = 'CombinedConsentMediumDirectEmail';
export const COMBINED_CONSENT_MEDIUM_EMAIL = 'CombinedConsentMediumEmail';
export const COMBINED_CONSENT_MEDIUM_INSIGHT_SURVEYS = 'CombinedConsentMediumInsightSurveys';
export const COMBINED_CONSENT_MEDIUM_SMS = 'CombinedConsentMediumSMS';

export const SMS_CONSENTS = {
  COMBINED_CONSENT_MEDIUM_SMS: 'CombinedConsentMediumSMS',
  SECONDARY_CONSENT_MEDIUM_SMS: 'SecondaryConsentMediumSMS',
  PRIMARY_CONSENT_MEDIUM_SMS: 'PrimaryConsentMediumSMS',
};

export const CONSENTS_MEDIUM = [
  'PrimaryConsentMedium',
  'SecondaryConsentMedium',
  'CombinedConsentMedium',
];

export const STUDY_STATUS = {
  MATCHED: 'Matched',
  INCOMPLETE: 'InComplete',
  UNMATCHED: 'UnMatched',
};
